var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "ws10-notification-icon--color-inherit";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <use xlink:href=\"/simplicity/icons/notification-tick.svg#icon\"></use>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <use xlink:href=\"/simplicity/icons/notification-info.svg#icon\"></use>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <use xlink:href=\"/simplicity/icons/notification-error.svg#icon\"></use>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg aria-hidden=\"true\" class=\"ws10-notification-icon ws10-notification-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optState") || (depth0 != null ? lookupProperty(depth0,"optState") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"optState","hash":{},"data":data,"loc":{"start":{"line":1,"column":78},"end":{"line":1,"column":90}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optColorInherit") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":91},"end":{"line":1,"column":158}}})) != null ? stack1 : "")
    + "\">\n\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optState") : depth0),"===","success",{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optState") : depth0),"===","info",{"name":"ifCond","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optState") : depth0),"===","warn",{"name":"ifCond","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optState") : depth0),"===","error",{"name":"ifCond","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "\n</svg>\n";
},"useData":true});