var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"ws10-number-badge-container ws10-number-badge-container--"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdIconSize") || (depth0 != null ? lookupProperty(depth0,"stdIconSize") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdIconSize","hash":{},"data":data,"loc":{"start":{"line":3,"column":78},"end":{"line":3,"column":93}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optIsLTOneDigit") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":94},"end":{"line":3,"column":178}}})) != null ? stack1 : "")
    + "\"  aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"optNotificationsNumber") || (depth0 != null ? lookupProperty(depth0,"optNotificationsNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optNotificationsNumber","hash":{},"data":data,"loc":{"start":{"line":3,"column":193},"end":{"line":3,"column":219}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"stdAriaLabel") || (depth0 != null ? lookupProperty(depth0,"stdAriaLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdAriaLabel","hash":{},"data":data,"loc":{"start":{"line":3,"column":220},"end":{"line":3,"column":236}}}) : helper)))
    + "\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optIsLThreeDigits") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":3,"column":238},"end":{"line":3,"column":310}}})) != null ? stack1 : "")
    + "</span>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-number-badge-container--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdIconSize") || (depth0 != null ? lookupProperty(depth0,"stdIconSize") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdIconSize","hash":{},"data":data,"loc":{"start":{"line":3,"column":147},"end":{"line":3,"column":162}}}) : helper)))
    + "--padded ";
},"4":function(container,depth0,helpers,partials,data) {
    return "999+";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"optNotificationsNumber") || (depth0 != null ? lookupProperty(depth0,"optNotificationsNumber") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optNotificationsNumber","hash":{},"data":data,"loc":{"start":{"line":3,"column":275},"end":{"line":3,"column":303}}}) : helper))) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"ws10-number-badge\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optNotificationsNumber") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "    <span class=\"ws10-number-badge__icon ws10-number-badge__icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdIconSize") || (depth0 != null ? lookupProperty(depth0,"stdIconSize") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"stdIconSize","hash":{},"data":data,"loc":{"start":{"line":5,"column":66},"end":{"line":5,"column":81}}}) : helper)))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":"color-monochrome-600","optSize":(depth0 != null ? lookupProperty(depth0,"stdIconSize") : depth0),"stdName":(depth0 != null ? lookupProperty(depth0,"stdIconName") : depth0),"stdClassName":"ws10-icon__svg"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </span>\n</span>\n";
},"usePartial":true,"useData":true});