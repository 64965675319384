var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import './number-badge.scss';
import renderTemplate from './_number-badge.hbs';
import { Pattern, getPropertiesFromClassName, } from '@vfde-brix/ws10/core';
import { createSystemIcon, getSystemIconName, } from '../system-icon';
import { NUMBER_BADGE_CONTAINER, CLASSNAME_SYSTEM_ICON, COLOR_MONOCHROME_600, SIZE_200, SIZE_225, NINE, NINE_HUNDRED_NINTY_NINE, TEN, } from './constants';
/**
 * WS10 NumberBadge
 */
var NumberBadge = /** @class */ (function (_super) {
    __extends(NumberBadge, _super);
    function NumberBadge() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * a function to get the size of system icon
     * @returns optsize
     */
    NumberBadge.prototype.getIconSize = function () {
        var systemIcon = this.containerElement.getElementsByClassName(CLASSNAME_SYSTEM_ICON)[0];
        var propertiesFromClassName = getPropertiesFromClassName(systemIcon.className, {
            optSize: [SIZE_200, SIZE_225],
        });
        var optSize = propertiesFromClassName.optSize;
        return optSize;
    };
    /**
     * initiate DOM elements
     */
    NumberBadge.prototype.initDomElements = function () {
        // initiate system icon
        var systemIconContainerElement = this.containerElement.getElementsByClassName(CLASSNAME_SYSTEM_ICON)[0];
        var systemIconProps = {
            stdName: getSystemIconName(this.containerElement),
            optColor: COLOR_MONOCHROME_600,
            optSize: this.getIconSize(),
            business: {},
        };
        createSystemIcon(systemIconContainerElement, systemIconProps);
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    NumberBadge.prototype.writeDom = function () {
        var renderProperties = __assign(__assign({}, this.properties), { optIsLTOneDigit: this.properties.optNotificationsNumber > NINE, optIsLThreeDigits: this.properties.optNotificationsNumber > NINE_HUNDRED_NINTY_NINE });
        this.containerElement.innerHTML = renderTemplate(renderProperties);
        this.initDomElements();
    };
    /**
     * Intentionally return same props because no props to get defaults
     */
    NumberBadge.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Get properties from DOM
     * select number badge container, then break it into notification number and aria label text
     * getting the notification number from the aria label instead of the content of the badge to always get the right noification number even in cases larger than 999
     */
    NumberBadge.prototype.readDom = function (business) {
        this.initDomElements();
        var container = this.containerElement.getElementsByClassName(NUMBER_BADGE_CONTAINER)[0];
        var ariaLabel = container === null || container === void 0 ? void 0 : container.getAttribute('aria-label');
        var notifications = parseInt(ariaLabel === null || ariaLabel === void 0 ? void 0 : ariaLabel.split(' ')[0], TEN) || 0;
        var ariaLabelString = ariaLabel === null || ariaLabel === void 0 ? void 0 : ariaLabel.slice((ariaLabel === null || ariaLabel === void 0 ? void 0 : ariaLabel.indexOf(' ')) + 1);
        var optSize = this.getIconSize();
        var properties = {
            stdIconName: getSystemIconName(this.containerElement),
            stdIconSize: optSize,
            optNotificationsNumber: notifications,
            stdAriaLabel: ariaLabelString,
            business: {},
        };
        return properties;
    };
    return NumberBadge;
}(Pattern));
export { NumberBadge };
/**
 * This function returns an instance of Belt Container
 */
export var createNumberBadge = function (containerElement, properties) {
    var numberBadge = new NumberBadge(containerElement, properties);
    numberBadge.init();
    return numberBadge;
};
