/**
 * WS10 number badge classname
 */
export var NUMBER_BADGE_BASE_CLASSNAME = 'ws10-number-badge';
/**
 * number badge container
 */
export var NUMBER_BADGE_CONTAINER = "".concat(NUMBER_BADGE_BASE_CLASSNAME, "-container");
/**
 * padded number badge
 */
export var NUMBER_BADGE_PADDING = "".concat(NUMBER_BADGE_CONTAINER, "--size-200--padded");
/**
 * system icon container className
 */
export var CLASSNAME_SYSTEM_ICON = "".concat(NUMBER_BADGE_BASE_CLASSNAME, "__icon");
/**
 * size-200 constant
 */
export var SIZE_200 = 'size-200';
/**
 * size-225 constant
 */
export var SIZE_225 = 'size-225';
/**
 * color-monochrome-600 contant
 */
export var COLOR_MONOCHROME_600 = 'color-monochrome-600';
/**
 * number 9 constant
 */
export var NINE = 9;
/**
 * number 999 constant
 */
export var NINE_HUNDRED_NINTY_NINE = 999;
/**
 * number 10 constant
 */
export var TEN = 10;
