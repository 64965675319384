/**
 * Base class name
 */
export var FORM_TEXT_INPUT_CLASSNAME = 'ws10-form-text-input';
/**
 * classname with state error
 */
export var FORM_TEXT_INPUT_CLASSNAME_ERROR = "".concat(FORM_TEXT_INPUT_CLASSNAME, "--error");
/**
 * classname with warning
 */
export var FORM_TEXT_INPUT_CLASSNAME_WARN = "".concat(FORM_TEXT_INPUT_CLASSNAME, "--warn");
/**
 * classname with success
 */
export var FORM_TEXT_INPUT_CLASSNAME_SUCCESS = "".concat(FORM_TEXT_INPUT_CLASSNAME, "--success");
/**
 * classname with disabled
 */
export var FORM_TEXT_INPUT_CLASSNAME_DISABLED = "".concat(FORM_TEXT_INPUT_CLASSNAME, "--disabled");
/**
 * classname with system icon
 */
export var FORM_TEXT_INPUT_CLASSNAME_WITH_SYSTEM_ICON = "".concat(FORM_TEXT_INPUT_CLASSNAME, "--has-system-icon");
/**
 * class name of actual input html element
 */
export var FORM_TEXT_INPUT_INPUT_CLASSNAME = "".concat(FORM_TEXT_INPUT_CLASSNAME, "__input");
/**
 * Notification icon container classname
 */
export var FORM_TEXT_INPUT_NOTIFICATION_ICON_CLASSNAME = "".concat(FORM_TEXT_INPUT_CLASSNAME, "__notification_icon-container");
/**
 * System icon container classname
 */
export var FORM_TEXT_INPUT_SYSTEM_ICON_CLASSNAME = "".concat(FORM_TEXT_INPUT_CLASSNAME, "__system_icon-container");
/**
 * System button icon classname
 */
export var FORM_TEXT_INPUT_SYSTEM_BUTTON_ICON_CLASSNAME = "".concat(FORM_TEXT_INPUT_CLASSNAME, "__button-icon");
/**
 * Format type mobile number
 */
export var FORM_TEXT_INPUT_MOBILE_NUMBER = 'mobile-number';
