var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import './button-link.scss';
import renderTemplate from './_button-link.hbs';
import { Pattern, getPropertiesFromClassName, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { WS10_BUTTON_LINK_BASE_CLASSNAME, WS10_BUTTON_LINK_DISABLED_CLASSNAME, WS10_BUTTON_LINK_ICON_CLASSNAME, WS10_BUTTON_LINK_LEFT_ICON_CLASSNAME, WS10_BUTTON_LINK_RIGHT_ICON_CLASSNAME, WS10_BUTTON_LINK_TEXT_CLASSNAME, } from './Constants';
import { getSystemIconName } from '../system-icon';
/**
 * Ws10ButtonLink Component class
 */
var ButtonLink = /** @class */ (function (_super) {
    __extends(ButtonLink, _super);
    function ButtonLink() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Toggle aria-expanded if element controls another
     */
    ButtonLink.prototype.toggleAriaExpanded = function (force) {
        if (!this.properties.stdAriaControls) {
            return;
        }
        var buttonLink = this.containerElement
            .getElementsByClassName(WS10_BUTTON_LINK_BASE_CLASSNAME)[0];
        var isExpanded = buttonLink.getAttribute('aria-expanded') === 'true';
        var shouldBeExpanded = force !== null && force !== void 0 ? force : !isExpanded;
        buttonLink
            .setAttribute('aria-expanded', "".concat(shouldBeExpanded));
        this.properties.optAriaExpanded = shouldBeExpanded;
    };
    ButtonLink.prototype.getDefaultProperties = function (newProperties) {
        if (!newProperties.type) {
            newProperties.type = 'ButtonLink';
        }
        if (newProperties.optDisabled === undefined) {
            newProperties.optDisabled = false;
        }
        if (newProperties.linkHref && newProperties.optTarget === undefined) {
            newProperties.optTarget = '_self';
        }
        return newProperties;
    };
    /**
     * Init the events
     */
    ButtonLink.prototype.initEvents = function (isInitial) {
        var _this = this;
        if (isInitial && this.properties.business.onClick) {
            this.containerElement.addEventListener('click', function (event) {
                var anchor = event.target.closest(".".concat(WS10_BUTTON_LINK_BASE_CLASSNAME));
                if (anchor) {
                    _this.properties.business.onClick(event, (anchor === null || anchor === void 0 ? void 0 : anchor.href) || null, _this.properties.stdTrackingName, anchor);
                }
            });
        }
    };
    ButtonLink.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    ButtonLink.prototype.readDom = function (business) {
        var _a;
        var patternElement = this.containerElement.getElementsByClassName(WS10_BUTTON_LINK_BASE_CLASSNAME)[0];
        var optAction = patternElement.getAttribute('data-action') || undefined;
        var linkHref = patternElement.getAttribute('href') || patternElement.getAttribute('data-href');
        var optDisabled = patternElement.classList.contains(WS10_BUTTON_LINK_DISABLED_CLASSNAME);
        var optTarget = (patternElement.getAttribute('target') || patternElement.getAttribute('data-target'));
        var stdLabel = this.containerElement.getElementsByClassName(WS10_BUTTON_LINK_TEXT_CLASSNAME)[0].innerHTML.trim();
        var propertiesFromClassName = getPropertiesFromClassName(patternElement.className, {
            optColorVariant: ['color-primary-200', 'color-monochrome-600', 'color-primary-200-inverse', 'color-monochrome-600-inverse'],
        });
        var stdTrackingName = patternElement.getAttribute('data-tracking-name');
        var optColorVariant = propertiesFromClassName.optColorVariant;
        var iconElements = patternElement.getElementsByClassName(WS10_BUTTON_LINK_ICON_CLASSNAME);
        var optIconPosition = this.getOptIconPosition(iconElements, patternElement);
        var stdSystemIconName = getSystemIconName(patternElement);
        var stdAriaControls = patternElement.getAttribute('aria-controls');
        var optAriaExpanded = patternElement.getAttribute('aria-expanded') === 'true';
        var stdAriaLabel = (_a = patternElement.getAttribute('aria-label')) !== null && _a !== void 0 ? _a : undefined;
        var properties = __assign(__assign(__assign(__assign(__assign(__assign({ type: 'ButtonLink' }, optTarget && { optTarget: optTarget }), linkHref && { linkHref: linkHref }), { optDisabled: optDisabled, optColorVariant: optColorVariant, stdLabel: stdLabel, optIconPosition: optIconPosition, business: business, stdSystemIconName: stdSystemIconName, stdAriaLabel: stdAriaLabel, optAction: optAction }), stdAriaControls && { stdAriaControls: stdAriaControls }), optAriaExpanded && { optAriaExpanded: optAriaExpanded }), stdTrackingName && { stdTrackingName: stdTrackingName });
        return removeUndefinedFieldsFromObject(properties);
    };
    ButtonLink.prototype.getOptIconPosition = function (iconElements, patternElement) {
        if (iconElements.length === 0) {
            return undefined;
        }
        var iconLeftElement = patternElement.getElementsByClassName(WS10_BUTTON_LINK_LEFT_ICON_CLASSNAME);
        var iconRightElement = patternElement.getElementsByClassName(WS10_BUTTON_LINK_RIGHT_ICON_CLASSNAME);
        var optIconPosition;
        if (iconLeftElement.length !== 0) {
            optIconPosition = 'left';
        }
        if (iconRightElement.length !== 0) {
            optIconPosition = 'right';
        }
        return optIconPosition;
    };
    return ButtonLink;
}(Pattern));
export { ButtonLink };
/**
 * This function returns an instance of Ws10ButtonLink Component
 */
export var createButtonLink = function (containerElement, businessLogicOrProperties) {
    var buttonLink = new ButtonLink(containerElement, businessLogicOrProperties);
    buttonLink.init();
    return buttonLink;
};
