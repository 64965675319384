var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './system-icon.scss';
import renderTemplate from './_system-icon.hbs';
import { Pattern, getPropertiesFromClassName, } from '@vfde-brix/ws10/core';
import { getSystemIconName, getSystemIconTitle, } from '@vfde-brix/ws10/system-icon/utils/getSystemIconProps';
import { SYSTEM_ICON_COLORS, SYSTEM_ICON_SIZES, } from '@vfde-brix/ws10/system-icon/Constants';
/**
 * WS10 SystemIcon
 */
var SystemIcon = /** @class */ (function (_super) {
    __extends(SystemIcon, _super);
    function SystemIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    SystemIcon.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * Intentionally return same props because no props to get defaults
     */
    SystemIcon.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Get properties from DOM
     */
    SystemIcon.prototype.readDom = function (business) {
        var stdName = getSystemIconName(this.containerElement);
        var svg = this.containerElement.getElementsByTagName('svg')[0];
        var className = __spreadArray([], __read(svg.classList), false).join(' ');
        var map = {
            optSize: SYSTEM_ICON_SIZES,
            optColor: SYSTEM_ICON_COLORS,
        };
        var stdIconTitle = getSystemIconTitle(this.containerElement);
        var _a = getPropertiesFromClassName(className, map), optSize = _a.optSize, optColor = _a.optColor;
        return {
            stdName: stdName,
            optSize: optSize,
            optColor: optColor,
            stdIconTitle: stdIconTitle,
            business: business,
        };
    };
    return SystemIcon;
}(Pattern));
export { SystemIcon };
/**
 * This function returns an instance of Icon
 */
export var createSystemIcon = function (containerElement, properties) {
    var systemIcon = new SystemIcon(containerElement, properties);
    systemIcon.init();
    return systemIcon;
};
