/**
 * Base class name
 */
export var FORM_ELEMENT_BLOCK_CLASSNAME = 'ws10-form-element-block';
/**
 * Error classname
 */
export var FORM_ELEMENT_BLOCK_CLASSNAME_ERROR = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "--error");
/**
 * Warning classname
 */
export var FORM_ELEMENT_BLOCK_CLASSNAME_WARN = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "--warn");
/**
 * Success classname
 */
export var FORM_ELEMENT_BLOCK_CLASSNAME_SUCCESS = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "--success");
/**
 * Label classname
 */
export var FORM_ELEMENT_BLOCK_LABEL_CLASSNAME = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "__label-container");
/**
 * Input container classname
 */
export var FORM_ELEMENT_BLOCK_INPUT_CLASSNAME = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "__input-container");
/**
 * Loading anmation container classname
 */
export var FORM_ELEMENT_BLOCK_LOADING_ANIMATION_CLASSNAME = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "__loading-animation");
/**
 * Helper text classname
 */
export var FORM_ELEMENT_BLOCK_HELPER_TEXT_CLASSNAME = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "__helper-text");
/**
 * Error message classname
 */
export var FORM_ELEMENT_BLOCK_ERROR_MESSAGE_CLASSNAME = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "__error-message");
/**
 * In case optWarning is true we apply this class
 */
export var FORM_ELEMENT_BLOCK_SHOW_WARNING = "".concat(FORM_ELEMENT_BLOCK_CLASSNAME, "--show-warning");
/**
 * Type variations
 */
export var FORM_ELEMENT_BLOCK_TYPES = [
    'text-input', 'textarea', 'select',
];
/**
 * input password type
 */
export var INPUT_PASSWORD_TYPE = 'password';
/**
 * normal input type
 */
export var INPUT_TYPE = 'text';
/**
 * icon span class
 */
export var ICON_SPAN_SYSTEM = 'ws10-form-text-input__system_icon-container';
/**
 * icon button class
 */
export var ICON_BUTTON_SYSTEM = 'ws10-form-text-input__button-icon';
/**
 * input text class name
 */
export var INPUT_TEXT_CLASS = 'ws10-form-text-input';
/**
 * hide password icon
 */
export var HIDE_PASSWORD_ICON = 'data-hide-password-icon';
/**
 * show password icon
 */
export var SHOW_PASSWORD_ICON = 'data-show-password-icon';
/**
 * toggle password flage
 */
export var TOGGLE_PASSWORD_FLAG = 'data-hide-show-password-icon';
/**
 * default icon name
 */
export var DEFAULT_ICON_NAME = 'placeholder';
/**
 * default copy icon name
 */
export var COPY_ICON_NAME = 'copy';
/**
 * default copy show password name
 */
export var SHOW_PASSWORD_ICON_NAME = 'show-password';
/**
 * default copy hide password name
 */
export var HIDE_PASSWORD_ICON_NAME = 'hide-password';
export var ENTER = 'Enter';
export var SPACE = 'Space';
export var COPY_ACTION_DIV_ID = 'copyAction';
export var ARIA_LABEL_COPIED_MSG = 'In die Zwischenablage kopiert';
