var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "radio";
},"3":function(container,depth0,helpers,partials,data) {
    return "checkbox";
},"5":function(container,depth0,helpers,partials,data) {
    return "name=\"filter-group\"";
},"7":function(container,depth0,helpers,partials,data) {
    return "checked";
},"9":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"11":function(container,depth0,helpers,partials,data) {
    return "checked disabled";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":"color-inherit","optAriaHidden":true,"optSize":"size-125","stdName":(depth0 != null ? lookupProperty(depth0,"optIcon") : depth0),"stdClassName":"ws10-filter-pill__item-icon"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li id=\"filter-pill-item-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":34}}}) : helper)))
    + "\" class=\"ws10-filter-pill__item\">\n    <input\n        id=\"filter-pill-input-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":39}}}) : helper)))
    + "\" \n        class=\"ws10-filter-pill__item-input ws10-sr-only\"\n        type=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optSingleSelection") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":5,"column":68}}})) != null ? stack1 : "")
    + "\"\n        value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdValue") || (depth0 != null ? lookupProperty(depth0,"stdValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdValue","hash":{},"data":data,"loc":{"start":{"line":6,"column":15},"end":{"line":6,"column":27}}}) : helper)))
    + "\"\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optSingleSelection") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":60}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optState") : depth0),"===","checked",{"name":"ifCond","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":62}}})) != null ? stack1 : "")
    + " \n        "
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optState") : depth0),"===","disabled",{"name":"ifCond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":64}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optState") : depth0),"===","checked-disabled",{"name":"ifCond","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":80}}})) != null ? stack1 : "")
    + "\n    >\n    <label class=\"ws10-filter-pill__item-label\" for=\"filter-pill-input-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":12,"column":71},"end":{"line":12,"column":80}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optIcon") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + "        <span class=\"ws10-filter-pill__item-text ws10-text ws10-spacing-0\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdPillLabel") || (depth0 != null ? lookupProperty(depth0,"stdPillLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdPillLabel","hash":{},"data":data,"loc":{"start":{"line":16,"column":75},"end":{"line":16,"column":91}}}) : helper)))
    + "</span>\n    </label>\n</li>";
},"usePartial":true,"useData":true});