var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " ws10-accordion--open-individually";
},"3":function(container,depth0,helpers,partials,data) {
    return " ws10-accordion--scroll-to-opened-item";
},"5":function(container,depth0,helpers,partials,data) {
    return " ws10-accordion--deeplink";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <details id=\"ws10-accordion-item-details-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stdId") : depth0), depth0))
    + "\" class=\"ws10-accordion__item-details"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"optOpen") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":100},"end":{"line":3,"column":162}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"optHidden") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":162},"end":{"line":3,"column":206}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"optOpen") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":207},"end":{"line":3,"column":239}}})) != null ? stack1 : "")
    + ">\n            <summary class=\"ws10-accordion__item-summary ws10-accordion__item-summary-content\" aria-controls=\"ws10-accordion-item-content-"
    + alias2(((helper = (helper = lookupProperty(helpers,"stdId") || (depth0 != null ? lookupProperty(depth0,"stdId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"stdId","hash":{},"data":data,"loc":{"start":{"line":4,"column":138},"end":{"line":4,"column":147}}}) : helper)))
    + "\" aria-expanded=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"optOpen") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"stdIconLeft") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":7,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"optTagHeadline") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":16,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optAriaHidden":true,"optColor":"color-primary-200","optSize":"size-200","stdName":"chevron-down","stdClassName":"ws10-accordion__item-chevron"},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </summary>\n            <div id=\"ws10-accordion-item-content-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stdId") : depth0), depth0))
    + "\" class=\"ws10-accordion__item-content\">\n                <div class=\"ws10-accordion__item-content-inner\">\n                    "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"containerAnyComponent") : depth0), depth0)) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </details>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " ws10-accordion__item-details--open";
},"10":function(container,depth0,helpers,partials,data) {
    return " ws10-is-hidden";
},"12":function(container,depth0,helpers,partials,data) {
    return " open";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optAriaHidden":true,"optColor":"color-monochrome-600","optSize":"size-200","stdName":(depth0 != null ? lookupProperty(depth0,"stdIconLeft") : depth0),"stdClassName":"ws10-accordion__item-icon"},"data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"optTagHeadline") || (depth0 != null ? lookupProperty(depth0,"optTagHeadline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optTagHeadline","hash":{},"data":data,"loc":{"start":{"line":9,"column":25},"end":{"line":9,"column":45}}}) : helper))) != null ? stack1 : "")
    + " class=\"ws10-accordion__item-headline\">\n                        "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"stdHeadline") : depth0), depth0)) != null ? stack1 : "")
    + "\n                         </"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"optTagHeadline") || (depth0 != null ? lookupProperty(depth0,"optTagHeadline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optTagHeadline","hash":{},"data":data,"loc":{"start":{"line":11,"column":27},"end":{"line":11,"column":47}}}) : helper))) != null ? stack1 : "")
    + ">\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"ws10-accordion__item-headline\">\n                        "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"stdHeadline") : depth0), depth0)) != null ? stack1 : "")
    + "\n                         </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-accordion ws10-accordion--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optVariant") || (depth0 != null ? lookupProperty(depth0,"optVariant") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"optVariant","hash":{},"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":57}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optOpenIndividually") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":57},"end":{"line":1,"column":125}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optScrollToOpenedItem") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":125},"end":{"line":1,"column":199}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optDeepLink") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":199},"end":{"line":1,"column":250}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":25,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});