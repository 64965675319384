var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-form-element-block--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optState") || (depth0 != null ? lookupProperty(depth0,"optState") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optState","hash":{},"data":data,"loc":{"start":{"line":3,"column":46},"end":{"line":3,"column":58}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return " ws10-form-element-block--show-warning";
},"5":function(container,depth0,helpers,partials,data) {
    return " ws10-form-element-block--disabled";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"ws10-form-element-block__label-container\">\n"
    + ((stack1 = container.invokePartial(require("../form-label/_form-label.hbs"),(depth0 != null ? lookupProperty(depth0,"containerLabel") : depth0),{"name":"_form-label","hash":{"optLabelId":(depth0 != null ? lookupProperty(depth0,"stdInputAriaLabeledBy") : depth0),"optDisabled":(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),"optState":(depth0 != null ? lookupProperty(depth0,"optState") : depth0),"txtText":(depth0 != null ? lookupProperty(depth0,"stdLabel") : depth0),"stdIdRelatedElement":(depth0 != null ? lookupProperty(depth0,"stdId") : depth0)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../form-text-input/_form-text-input.hbs"),(depth0 != null ? lookupProperty(depth0,"containerInput") : depth0),{"name":"_form-text-input","hash":{"stdInputAriaLabeledBy":(depth0 != null ? lookupProperty(depth0,"stdInputAriaLabeledBy") : depth0),"optHideText":(depth0 != null ? lookupProperty(depth0,"optHideText") : depth0),"stdInputAriaLabel":(depth0 != null ? lookupProperty(depth0,"stdInputAriaLabel") : depth0),"stdIconTitle":(depth0 != null ? lookupProperty(depth0,"stdIconTitle") : depth0),"optAriaLive":(depth0 != null ? lookupProperty(depth0,"optAriaLive") : depth0),"optInputType":(depth0 != null ? lookupProperty(depth0,"optInputType") : depth0),"optReadOnly":(depth0 != null ? lookupProperty(depth0,"optReadOnly") : depth0),"stdPlaceholder":(depth0 != null ? lookupProperty(depth0,"stdPlaceholder") : depth0),"optAutoCompleteOff":(depth0 != null ? lookupProperty(depth0,"optAutoCompleteOff") : depth0),"stdAriaAutocomplete":(depth0 != null ? lookupProperty(depth0,"stdAriaAutocomplete") : depth0),"stdAriaControls":(depth0 != null ? lookupProperty(depth0,"stdAriaControls") : depth0),"optRole":(depth0 != null ? lookupProperty(depth0,"optRole") : depth0),"optState":(depth0 != null ? lookupProperty(depth0,"optState") : depth0),"stdValue":(depth0 != null ? lookupProperty(depth0,"stdValue") : depth0),"optDisabled":(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),"stdId":(depth0 != null ? lookupProperty(depth0,"stdId") : depth0),"optSystemIcon":(depth0 != null ? lookupProperty(depth0,"optSystemIcon") : depth0),"stdName":(depth0 != null ? lookupProperty(depth0,"stdName") : depth0),"optType":(depth0 != null ? lookupProperty(depth0,"optType") : depth0)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../form-textarea/_form-textarea.hbs"),(depth0 != null ? lookupProperty(depth0,"containerInput") : depth0),{"name":"_form-textarea","hash":{"optState":(depth0 != null ? lookupProperty(depth0,"optState") : depth0),"stdValue":(depth0 != null ? lookupProperty(depth0,"stdValue") : depth0),"optDisabled":(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),"stdId":(depth0 != null ? lookupProperty(depth0,"stdId") : depth0),"stdName":(depth0 != null ? lookupProperty(depth0,"stdName") : depth0)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../form-select/_form-select.hbs"),(depth0 != null ? lookupProperty(depth0,"containerInput") : depth0),{"name":"_form-select","hash":{"optState":(depth0 != null ? lookupProperty(depth0,"optState") : depth0),"stdValue":(depth0 != null ? lookupProperty(depth0,"stdValue") : depth0),"optDisabled":(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),"stdId":(depth0 != null ? lookupProperty(depth0,"stdId") : depth0),"stdName":(depth0 != null ? lookupProperty(depth0,"stdName") : depth0)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"ws10-form-element-block__loading-animation ws10-is-hidden\">\n"
    + ((stack1 = container.invokePartial(require("../loading-animation/_loading-animation.hbs"),depth0,{"name":"_loading-animation","hash":{"optSize":(depth0 != null ? lookupProperty(depth0,"optLoadingAnimationSize") : depth0)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"ws10-form-element-block__helper-text ws10-text-smaller\"\n              aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdHelperText") || (depth0 != null ? lookupProperty(depth0,"stdHelperText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdHelperText","hash":{},"data":data,"loc":{"start":{"line":34,"column":26},"end":{"line":34,"column":43}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdHelperText") || (depth0 != null ? lookupProperty(depth0,"stdHelperText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdHelperText","hash":{},"data":data,"loc":{"start":{"line":34,"column":45},"end":{"line":34,"column":62}}}) : helper)))
    + "</span>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"ws10-form-element-block__error-message ws10-text-smaller\"\n              aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdErrorMessage") || (depth0 != null ? lookupProperty(depth0,"stdErrorMessage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdErrorMessage","hash":{},"data":data,"loc":{"start":{"line":39,"column":26},"end":{"line":39,"column":45}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdErrorMessage") || (depth0 != null ? lookupProperty(depth0,"stdErrorMessage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdErrorMessage","hash":{},"data":data,"loc":{"start":{"line":39,"column":47},"end":{"line":39,"column":66}}}) : helper)))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-form-element-block\n    ws10-form-element-block--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optInputFieldType") || (depth0 != null ? lookupProperty(depth0,"optInputFieldType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"optInputFieldType","hash":{},"data":data,"loc":{"start":{"line":2,"column":29},"end":{"line":2,"column":50}}}) : helper)))
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optState") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":65}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optWarnForValidation") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":77}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":64}}})) != null ? stack1 : "")
    + "\"\n>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdLabel") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"ws10-form-element-block__input-container\">\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optInputFieldType") : depth0),"===","text-input",{"name":"ifCond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optInputFieldType") : depth0),"===","textarea",{"name":"ifCond","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optInputFieldType") : depth0),"===","select",{"name":"ifCond","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optWithLoadingAnimation") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdHelperText") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":35,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdErrorMessage") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":4},"end":{"line":40,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});